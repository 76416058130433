import { Getter } from "vuex";
import { IState } from './state';

const firstItem: Getter<IState, IState> = (state, getters) => {
	const event = state.isMobile ? state.events[state.currentIndex] : state.events[state.currentIndex * 2]
	return event;
}
	
	
const secondItem: Getter<IState, IState> = (state, getters) => {
	const event = state.isMobile ? null : state.events[state.currentIndex * 2 + 1]
	return event
}

const indexes: Getter<IState, IState> = (state, getters) => {
	const indexes = state.isMobile ? state.events.length : Math.ceil(state.events.length / 2)
	return indexes
};

export default {
	indexes,
	firstItem,
	secondItem,
}
