export interface IState {
	events: IEvent[]
	currentIndex: number
	isMobile: boolean
}

export interface IEvent {
	title: string
	imageURI: string
	linkURI: string
	startDate: number
	endDate: number
}

const deafultState: IState = {
	events: [],
	currentIndex: 0,
	isMobile: false
}

export default deafultState;
