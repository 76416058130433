import { MutationTree, Mutation } from 'vuex';
import { IState } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const decrementIndexMutation: MutationHandler<IState> = (
	state,
	{ payload }
  ) => {
		const indexes = state.isMobile ? state.events.length - 1 : Math.ceil(state.events.length / 2) - 1;
		state.currentIndex = state.currentIndex <= 0 ? indexes : state.currentIndex - 1;
};

const incrementIndexMutation: MutationHandler<IState> = (
	state,
	{ payload }
) => {	
		const indexes = state.isMobile ? state.events.length - 1 : Math.ceil(state.events.length / 2) - 1;
		state.currentIndex = state.currentIndex >= indexes ? 0 : state.currentIndex + 1;
  };

const setIndexMutation: MutationHandler<number> = (state, { payload }) => {
	state.currentIndex = payload;
};

const checkMobileMutation: MutationHandler<number> = (state, { payload }) => {
	const indexes = payload < 1024 ? state.events.length - 1 : Math.ceil(state.events.length / 2) - 1;
	if(state.currentIndex >= indexes || state.currentIndex <= 0) Object.assign(state, { currentIndex: 0 })

	Object.assign(state, { isMobile: payload < 1024 })
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	decrementIndex: decrementIndexMutation,
	incrementIndex: incrementIndexMutation,
	setIndex: setIndexMutation,
	checkMobile: checkMobileMutation
}

export default mutations
