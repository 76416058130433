
import { IEvent } from "../store/state";
import Vue from "vue";
  
// Interfaces for component typechecking!
interface StepperData {}

interface StepperMethods {
  setIndex(index: number): void;
}

interface StepperComputed {
  currentIndex: number;
  events: IEvent[];
  indexes: number;
}

interface StepperProps {}

export default Vue.extend<
  StepperData,
  StepperMethods,
  StepperComputed,
  StepperProps
>({
  name: "Stepper",
  methods: {
    setIndex(index: number) {
      this.$store.commit({
        type: "setIndex",
        payload: index,
      });
    },
  },
  computed: {
    currentIndex() {
      return this.$store.state.currentIndex;
    },
    events() {
      return this.$store.state.events;
    },
    indexes() {
      return this.$store.getters.indexes;
    },
  },
  components: {},
});
