
import Vue from "vue";
import { IEvent } from "../store/state";
import Stepper from "./Stepper.vue";

interface EventData {}

interface EventMethods {}

interface EventComputed {
}

interface EventProps {
    event: IEvent
    fullwidth: boolean
}
  
export default Vue.extend<
EventData,
EventMethods,
EventComputed,
EventProps
>({
    name: "EventDesktop",
    props: {
        event: {},
        fullwidth: () => false
    },
    methods: {},
    computed: {},
    components: {
        Stepper
    }
});
