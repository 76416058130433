
import Vue from 'vue'
import Event from './components/Event.vue';
import Pagination from './components/Pagination.vue';
import { IEvent } from './store/state';

// Interfaces for component typechecking!
interface AppData {}

interface AppMethods {}

interface AppComputed {
	firstItem: IEvent
	secondItem: IEvent
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		firstItem() {
				return this.$store.getters.firstItem;
		},
		secondItem() {
				return this.$store.getters.secondItem;
		},
	},
	created() {
		this.$store.commit({
			type: 'checkMobile',
			payload: window.innerWidth
		})

		addEventListener('resize', () => {
			const width = Math.max(
				document.body.scrollWidth,
				document.documentElement.scrollWidth,
				document.body.offsetWidth,
				document.documentElement.offsetWidth,
				document.documentElement.clientWidth,
				window.innerWidth
				);
				
			this.$store.commit({
				type: 'checkMobile',
				payload: width
			})
		})
	},
	components: {
		Event,
		Pagination
	}
})
