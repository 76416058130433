
import Vue from "vue";
import { IEvent } from "../store/state";
import Stepper from "./Stepper.vue";

interface EventSliderData {}

interface EventSliderMethods {
    incrementIndex(): void
    decrementIndex(): void
}

interface EventSliderComputed {
    events: IEvent[];
}

interface EventSliderProps {
}
  
export default Vue.extend<
EventSliderData,
EventSliderMethods,
EventSliderComputed,
EventSliderProps
>({
    name: "EventSliderDesktop",
    methods: {
        incrementIndex() {
            this.$store.commit({
                type:"incrementIndex", payload: null
            });
        },
        decrementIndex() {
            this.$store.commit({ 
                type:"decrementIndex", payload: null
            });
        }
    },
    computed: {
        events() {
            return this.$store.state.events;
        }
    },
    components: {
        Stepper
    }
});
